import { useState } from 'react'

import Container from './Container'
import { Spacing, FlexibleHeading as Heading, BrandColor } from '@big-red-group/storefront-common-ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { SwiperButtons, SwiperHeadingContainer, SwiperLeft, SwiperRight } from './Swiper.styles'
import { ParallaxProvider } from 'react-scroll-parallax'
import 'swiper/css'
import 'swiper/css/navigation'
import {
  BackgroundCont,
  Background,
  ProductTiles,
  Wrapper,
  ButtonContainer,
  MobileViewAllButton,
  DesktopViewAllButton,
  ViewMoreWrapper,
  IconWrapper,
} from '@/components/ProductCollection.styles'
import { HeadingSize, Product, ProductCollectionStyle, ProductCollectionViewMore } from '@/types'
import ProductTile from '@/components/ProductTile'
import { generateHtmlSafeId } from '@/helpers/UtilHelper'
import Icon from '@/components/Icons'
import { useProductClicked } from '@/hooks/AnalyticsProductHook'
import { useProductListImpression } from '@/hooks/AnalyticsImpressionHook'
import { LIST_SIZE } from '@/constant/segment'
import { generateCarouselItems } from '@/helpers/GoogleSearchTagHelper'

const ProductCollection: React.FC<{
  products: Product[]
  sectionTitle?: string
  variant?: ProductCollectionStyle
  backgroundImage?: string
  urlSegment?: string
  showCounter?: boolean
  backgroundColor?: BrandColor
  id: string
  smallMobileTile?: boolean
  viewMore?: ProductCollectionViewMore
  prefixElement?: React.ReactNode
  titleSize?: HeadingSize
  hidePrice?: boolean
}> = ({
  products,
  sectionTitle,
  variant = 'default',
  backgroundImage,
  urlSegment,
  showCounter = false,
  backgroundColor = 'bg-white',
  id,
  smallMobileTile = true,
  viewMore,
  prefixElement,
  titleSize = 'L',
  hidePrice = false,
}) => {
  const headingColor: BrandColor = variant === 'colored' ? 'brand-accent' : 'text-primary'
  const swiperNavigationThreshold = 4 - (viewMore ? 1 : 0) - (prefixElement ? 1 : 0)
  const swiperId = generateHtmlSafeId(id)
  const trackProductClicked = useProductClicked()

  const [lastIndex, setLastIndex] = useState(LIST_SIZE)

  // This is used to ensure the first Product List Viewed event will only be fired on first impression
  // TODO: Figure out whether list ID is available
  const firstImpressionRef = useProductListImpression({
    products: products?.slice(lastIndex - LIST_SIZE, lastIndex),
    listName: sectionTitle,
  })

  const handlePrev = () => {
    if (lastIndex === LIST_SIZE) return
    setLastIndex(lastIndex - 1)
  }

  const handleNext = () => {
    if (lastIndex === products.length) return
    setLastIndex(lastIndex + 1)
  }

  const handleClickViewAll = () => {
    window.location.href = urlSegment!
  }

  const renderViewMore = (cta: string, link: string) => {
    return (
      <ViewMoreWrapper href={link} data-testid="speedbump-cta">
        <Heading size="S" as="h3" color="brand-accent">
          {cta}
        </Heading>
        <IconWrapper>
          <Icon name="arrowRight" width={30} height={30} />
        </IconWrapper>
      </ViewMoreWrapper>
    )
  }

  return (
    <ParallaxProvider>
      <Wrapper backgroundColor={backgroundColor} variant={variant}>
        {variant === 'colored' && (
          <>
            <BackgroundCont>
              <Background
                variant={variant}
                backgroundImage={backgroundImage}
                data-testid="product-tile-collection-background"
              />
            </BackgroundCont>
            <Spacing size="XXL" />
          </>
        )}
        <Container spacing="M">
          <SwiperHeadingContainer centeredArrows={false} ref={firstImpressionRef}>
            <Heading size={titleSize} color={headingColor}>
              {sectionTitle}
            </Heading>
            <ButtonContainer>
              {products.length > swiperNavigationThreshold && (
                <SwiperButtons>
                  <SwiperLeft className={`${swiperId}-prev`} onClick={handlePrev} />
                  <SwiperRight className={`${swiperId}-next`} onClick={handleNext} />
                </SwiperButtons>
              )}

              {urlSegment && (
                <DesktopViewAllButton size="S" onClick={() => handleClickViewAll()}>
                  View all
                </DesktopViewAllButton>
              )}
            </ButtonContainer>
          </SwiperHeadingContainer>
        </Container>
        {generateCarouselItems({
          products,
          prefixUrl: '',
        })}
        <ProductTiles>
          <Swiper
            modules={[Navigation]}
            navigation={{ prevEl: `.${swiperId}-prev`, nextEl: `.${swiperId}-next` }}
            breakpoints={{
              0: {
                spaceBetween: 16,
                slidesPerView: 'auto',
                loop: false,
                lazyPreloadPrevNext: 1,
              },
              768: {
                spaceBetween: 16,
                slidesPerView: 3,
                loop: false,
                lazyPreloadPrevNext: 1,
              },
              1100: {
                spaceBetween: 24,
                slidesPerView: 4,
                loop: false,
                lazyPreloadPrevNext: 1,
              },
            }}
          >
            {prefixElement && (
              <SwiperSlide key="prefix-slide" className="prefix-slide">
                {prefixElement}
              </SwiperSlide>
            )}
            {products.map(
              (
                { id, title, supplier, price, region, canonicalRegionUrlSegment, image, url, fromPrice, rating },
                index
              ) => (
                <SwiperSlide className="prod-swiper-tile" key={id}>
                  <ProductTile
                    id={id}
                    title={title}
                    supplier={supplier}
                    price={price}
                    rating={rating}
                    fromPrice={fromPrice}
                    region={region}
                    canonicalRegionUrlSegment={canonicalRegionUrlSegment}
                    image={image}
                    url={url}
                    onClick={() =>
                      trackProductClicked({
                        product: products[index],
                        position: index + 1,
                        isRecommended: true,
                        listTitle: sectionTitle,
                        isCarousel: true,
                      })
                    }
                    mobileCardTile={!showCounter && smallMobileTile}
                    counter={showCounter ? index + 1 : undefined}
                    hidePrice={hidePrice}
                  />
                </SwiperSlide>
              )
            )}
            {viewMore && (
              <SwiperSlide key={viewMore.cta} className="prod-swiper-tile">
                {renderViewMore(viewMore.cta, viewMore.link)}
              </SwiperSlide>
            )}
          </Swiper>
        </ProductTiles>
        {urlSegment && (
          <Container>
            <Spacing size="L" />
            <MobileViewAllButton secondary size="S">
              View all
            </MobileViewAllButton>
          </Container>
        )}
      </Wrapper>
    </ParallaxProvider>
  )
}

export default ProductCollection
