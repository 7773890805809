import styled from 'styled-components'
import { BREAKPOINT_SMALL } from '@/constant/ui'

export const SwiperLeft = styled.div`
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:before {
    content: '';
    display: block;
    width: 30%;
    height: 30%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-width: 2px 0 0 2px;
  }
`
export const SwiperRight = styled.div`
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:before {
    content: '';
    display: block;
    width: 30%;
    height: 30%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    border-width: 2px 2px 0 0;
  }
`

export const SwiperButtons = styled.div<{ color?: string }>`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }

  ${SwiperLeft}::before,
  ${SwiperRight}::before {
    border-style: solid;
    border-color: var(--${({ color }) => color ?? 'text-primary'});
  }

  .swiper-button-disabled::before {
    border-color: var(--text-primary-light);
  }

  color: ${({ color }) => (color ? color : 'var(--brand-secondary)')};
`

export const SwiperHeadingContainer = styled.div<{ centeredArrows: boolean }>`
  display: flex;
  justify-content: ${({ centeredArrows }) => (centeredArrows ? 'center' : 'space-between')};
  align-items: flex-end;
  gap: 20px;
`
