import styled from 'styled-components'
import { ProductCollectionStyle } from '@/types'
import { BREAKPOINT_LARGE, BREAKPOINT_SMALL } from '@/constant/ui'
import Button from '@/components/Button'
import { BrandColor } from '@big-red-group/storefront-common-ui'

export const Wrapper = styled.div<{ backgroundColor: BrandColor; variant: ProductCollectionStyle }>`
  background-color: var(--${({ backgroundColor }) => backgroundColor});
  position: relative;

  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    justify-content: flex-end;

    ${({ variant }) =>
      variant === 'default' &&
      `
      height: auto;
    `}

    .prod-swiper-tile {
      width: fit-content;

      .product-tile:not(.mobile-card) .product-tile__image {
        height: 180px;
        margin-bottom: 10px;
      }
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const DesktopViewAllButton = styled(Button)`
  display: block;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }
`

export const MobileViewAllButton = styled(Button)`
  display: none;
  width: 100%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: block;
  }
`

export const ProductTiles = styled.div`
  .swiper {
    padding-left: calc((100% - 1296px) / 2);
    padding-right: calc((100% - 1296px) / 2);
  }
  .swiper-slide {
    height: auto;
  }
  @media (max-width: ${BREAKPOINT_LARGE}px) {
    .swiper {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100vw;
    left: 0;

    .clink-swiper-tile {
      width: 65vw;
    }
    .swiper {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`

export const BackgroundCont = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  top: -5%;

  > div {
    height: 100%;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 60%;
  }
`

export const Background = styled.div<{ backgroundImage?: string; variant: ProductCollectionStyle }>`
  background-color: unset;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;

  ${({ variant }) =>
    variant === 'colored' &&
    `
      background-color: var(--brand-secondary);
  `}
`

export const ViewMoreWrapper = styled.a`
  display: flex;
  background-color: var(--brand-secondary);
  border-radius: var(--radius-l);
  box-sizing: border-box;
  height: 100%;
  padding: 40px;
  text-decoration: none;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    height: max-content;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 18px;

    width: 200px;
  }
`

export const IconWrapper = styled.div`
  color: var(--text-inverse);
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    svg {
      width: 30px;
      height: 30px;
    }
  }
`
