import Container from './Container'
import Icon, { IconName } from './Icons'
import { Wrapper, TrustFactors, Point, IconWrap, DesktopText, MobileText } from './TrustFactorBar.styles'
import { Alignment, HeadingSize } from '@/types'
import { FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'

export const ADR_TRUST_FACTORS: { mobileText: string; desktopText: string; icon: IconName }[] = [
  {
    mobileText: '4.7 / 5 <span>&starf;</span> rating',
    desktopText: '4.7 / 5 <span>&starf;</span> from 40k+ reviews',
    icon: 'starOutlined',
  },
  {
    mobileText: '5 year gift validity',
    desktopText: 'Gift vouchers valid for 5 years',
    icon: 'present',
  },
  {
    mobileText: 'Australian owned',
    desktopText: 'Australian owned',
    icon: 'australia',
  },
  {
    mobileText: 'Free exchanges',
    desktopText: 'Quick and easy free exchanges',
    icon: 'exchange',
  },
]

const TrustFactorBar: React.FC<{
  miniBar?: boolean
  title?: string
  titleSize?: HeadingSize
  titleAlignment?: Alignment
}> = ({ miniBar = false, title, titleSize = 'M', titleAlignment = 'center' }) => {
  return (
    <Wrapper miniBar={miniBar}>
      <Container>
        {title && (
          <Heading size={titleSize} alignment={titleAlignment} as="h2" color='text-inverse'>
            {title}
          </Heading>
        )}
        <TrustFactors miniBar={miniBar}>
          {ADR_TRUST_FACTORS.map(({ desktopText, mobileText, icon }, index) => {
            return (
              <Point key={index}>
                <IconWrap>
                  <Icon name={icon} />
                </IconWrap>
                <div>
                  <DesktopText miniBar={miniBar} dangerouslySetInnerHTML={{ __html: desktopText }}></DesktopText>
                  <MobileText miniBar={miniBar} dangerouslySetInnerHTML={{ __html: mobileText }}></MobileText>
                </div>
              </Point>
            )
          })}
        </TrustFactors>
      </Container>
    </Wrapper>
  )
}

export default TrustFactorBar
