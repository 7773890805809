import { Product, ProductRecommendation, UseAnalyticsObserverConfig } from '@/types'

import useAnalyticsObserver from './AnalyticsObserverHook'
import { useProductListViewed } from './AnalyticsProductHook'

type ImpressionInput = {
  products: (Product | ProductRecommendation)[]
  listId?: string
  listName?: string
}

export const useProductListImpression = ({
  products,
  listId,
  listName,
}: ImpressionInput): ((node?: Element | null | undefined) => void) => {
  const trackProductListViewed = useProductListViewed(listName, listId)

  const config: UseAnalyticsObserverConfig = {
    onCreateImpressionWhenView: () => {
      trackProductListViewed(products)
    },
  }

  const { inViewRef } = useAnalyticsObserver(config)

  return inViewRef
}
