import { Product, ProductRecommendation } from '@/types'
import { CANONICAL_BASE_URL } from './UrlHelper'

let ind = 0
export function generateCarouselItems(param: { products: ProductRecommendation[] | Product[]; prefixUrl: string }) {
  const { products, prefixUrl } = param
  return products?.length > 0 ? (
    <div style={{ display: 'none' }} itemScope itemType="https://schema.org/ItemList">
      <>
        {products?.map((p, i) => {
          ind++
          if ('productId' in p) {
            const { productId, productName, regionUrlSegment, productUrlSegment } = p
            return (
              <div key={productId} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                <meta itemProp="position" content={`${ind}`} />
                <a itemProp="url" href={`https://${CANONICAL_BASE_URL}${prefixUrl}/${regionUrlSegment}/${productUrlSegment}`}>
                  {productName}
                </a>
              </div>
            )
          }
          if ('id' in p) {
            const { id, url, title } = p
            return (
              <div key={id} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                <meta itemProp="position" content={`${ind}`} />
                <a itemProp="url" href={`https://${CANONICAL_BASE_URL}${prefixUrl}${url}`}>
                  {title}
                </a>
              </div>
            )
          }
        })}
      </>
    </div>
  ) : null
}
