import { useEffect } from 'react'

import { ContentWrapper, HeadingWrapper, StylizedText, Wrapper } from '@/components/Error.styles'
import { FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import Button from '@/components/Button'
import BackgroundImage from '@/components/BackgroundImage'
import Container from '@/components/Container'
import router from 'next/router'
import { useAnalyticsError } from '@/hooks/AnalyticsErrorHook'

const ErrorPage: React.FC = () => {
  const errorMessage = 'Oops! something went wrong'

  // const router = useRouter()
  const errorOccurred = useAnalyticsError()

  useEffect(() => {
    errorOccurred({ name: 'Error Page', message: errorMessage }, true)
  }, [errorOccurred])

  return (
    <BackgroundImage imageUrl="/images/error-image.webp">
      <Wrapper>
        <Container>
          <ContentWrapper>
            <HeadingWrapper>
              <Heading as="h1" size="XL" color="text-inverse">
                Oops! Something went <StylizedText>wrong</StylizedText>
              </Heading>
              <Heading size="S" color="text-inverse" weight="regular">
                Adrenaline has encountered an error. Try refreshing or navigating to another page. If the problem
                persists please contact <a href={'mailTo:action@adrenaline.com.au'}>action&#64;adrenaline.com.au</a>
              </Heading>
            </HeadingWrapper>
            <Container size="fullWidth">
              <Button size="L" onClick={() => router.reload()}>
                Refresh page
              </Button>
            </Container>
          </ContentWrapper>
        </Container>
      </Wrapper>
    </BackgroundImage>
  )
}

export default ErrorPage
