import styled from 'styled-components'
import { BREAKPOINT_MEDIUM, BREAKPOINT_LARGE, Z_INDEX } from '@/constant/ui'

export const TrustFactors = styled.div<{ miniBar: boolean }>`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: ${({ miniBar }) =>
    miniBar ? 'repeat(3, minmax(0, calc(100% / 3)))' : 'repeat(4, minmax(0, 25%))'};
  text-align: ${({ miniBar }) => (miniBar ? 'center' : 'left')};
  gap: 35px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    grid-template-columns: repeat(3, minmax(0, calc(100% / 3)));
    gap: 30px;
  }
`

export const IconWrap = styled.div`
  position: relative;
  color: var(--brand-accent);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DesktopText = styled.span<{ miniBar: boolean }>`
  font-size: 13px;
  font-family: var(--font-medium);
  line-height: 1.1;
  display: block;
  display: ${({ miniBar }) => (miniBar ? 'none' : 'block')};

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    display: none;
  }
`
export const MobileText = styled.span<{ miniBar: boolean }>`
  display: ${({ miniBar }) => (miniBar ? 'block' : 'none')};

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    display: block;
  }
`
export const Point = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 40px;
  color: var(--text-inverse);

  svg {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: ${Z_INDEX.low};
  }

  > div:last-child {
    width: 200px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    > div:last-child {
      width: fit-content;
    }
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    font-size: 13px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: unset;
    gap: 6px;

    svg {
      width: 20px;
      height: 20px;
    }

    &:nth-child(4) {
      display: none;
    }
  }
`

export const Wrapper = styled.div<{ miniBar: boolean }>`
  background: var(--brand-secondary);
  padding: 10px 0;

  ${({ miniBar }) =>
    miniBar &&
    `
    @media (min-width: ${BREAKPOINT_MEDIUM}px) {
      width: 100%;
      margin: 0 auto;
      
      > div {
        padding: 0;
      }
      ${Point} {
        width: 100%;
        justify-content: flex-start;
        font-size: 13px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: unset;

        svg {
          width: 30px;
          height: 30px;
        }

        &:nth-child(4) {
          display: none;
        }
        
        > div:last-child {
          width: fit-content;
        }
      }
    }
  `}
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    padding: 15px 0;
  }
`
