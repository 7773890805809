import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { DEFAULT_IMPRESSION_TIME, DEFAULT_VISIBILITY } from '@/constant/segment'
import { UseAnalyticsObserverConfig } from '@/types'

const useAnalyticsObserver = ({
  timeToImpression = DEFAULT_IMPRESSION_TIME, // Set up default delay to be 2 seconds to ensure customer really has intention to view
  options,
  onCreateImpressionWhenView,
}: UseAnalyticsObserverConfig): {
  inViewRef: (node?: Element | null) => void
} => {
  const { ref, inView, entry } = useInView({
    ...options,
    threshold: options?.threshold || DEFAULT_VISIBILITY, // Set up default view percentage to be 75%
  })

  useEffect(() => {
    let fired = false
    let timer: number | null = null

    if (inView) {
      timer = window.setTimeout(() => {
        fired = true
        onCreateImpressionWhenView?.(entry)
      }, timeToImpression)
    }

    return () => {
      if (!fired && timer) {
        window.clearTimeout(timer)
      }
    }
  }, [entry, inView, timeToImpression, onCreateImpressionWhenView])

  return {
    inViewRef: ref,
  }
}

export default useAnalyticsObserver
